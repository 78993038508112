<template>
  <main class="mr-4">
    <trac-back-button>Back</trac-back-button>
    <trac-loading v-if="isLoading" />
    <trac-modal v-if="kycModal" :cancel="false">
      <div class="flex flex-col justify-center items-center">
        <img src="@/assets/pin-check-warning.svg" class="w-20 h-20" alt="" />

        <h1 class="font-bold my-6 text-center">Complete KYC to create <br> Wallet PIN</h1>

        <p class="text-center tracking-normal text-xs mb-8 -mt-2 px-8">
          You will need to complete your KYC to be <br> able to create wallet PIN.
        </p>
        <trac-button class="w-32 mt-2 mb-6" @button-clicked="$router.push({name: 'KycProfile'})">
          <span class="px-12 text-center uppercase"
            >Complete KYC</span
          >
        </trac-button>

        <!-- <button
          class="bg-none border-none text-primaryBlue text-xs outline-none font-semibold"
        >
          Skip to Settlement Preference
        </button> -->
      </div>
    </trac-modal>
    <h3 class="my-8 font-bold text-sm">KYC (Settlement Preference)</h3>

    <div class="shadow-lg rounded-md p-4 pt-8 md:w-9/12 mx-auto my-8 md:px-12">
      <h3 class="my-5 font-bold text-primaryBlue">Settlement Preference</h3>
      <div class="grid grid-cols-1 md:grid-cols-3 mx-4 md:mx-10 my-12 gap-4">
        <div
          @click="$router.push({ name: 'AddBankDetails' })"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center md:gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Settlement Account</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>

        <!-- Manage Wallet -->
        <div
          @click="$router.push(ConditionalDestination)"
          class="shadow-md rounded-md p-5 cursor-pointer hover:bg-accentLight flex items-center justify-between gap-3"
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p v-if="walletId" class="text-xs">Manage Wallet Pin</p>
            <p v-else class="text-xs">Create Wallet Pin</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
        <!-- End Manage Wallet -->
        <div
          @click="$router.push({ name: 'AddSettlementPreference' })"
          class="
            shadow-md
            rounded-md
            p-5
            cursor-pointer
            hover:bg-accentLight
            flex
            items-center
            justify-between
            gap-3
          "
        >
          <div class="flex items-center gap-3">
            <img
              src="./../../../assets/svg/pin-management/settlement-pref.svg"
              alt=""
            />
            <p class="text-xs">Settlement Preference</p>
          </div>
          <img
            src="./../../../assets/svg/pin-management/chevron-icon-right.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      fetchedKycDetails: null,
      isLoading: true,
      kycModal: false,
      walletId: null,
    };
  },
  async created () {
    let result = await this.$store.dispatch("VERIFY_KYC");
    let wallet = await this.$store.dispatch("FETCH_WALLET_DETAILS");
    console.log('Wallet DEtails',wallet)
    if (wallet.status) {
      this.walletId = wallet.data.walletid
    }
    this.fetchedKycDetails = result.data || [];
    this.isLoading = false;
  },
  computed: {
    isFullyVerified() {
      return this.fetchedKycDetails.every((rule) => rule.status === "VERIFIED");
    },
    ConditionalDestination() {
      // write en empty if/else block
      if (this.walletId) {
        return {
          name: 'PinManagment'
        }
      } 
      else {
        return {
          name: "ConfirmPinInput",
          query: {
            ops: "new_pin",
            onboarding: true,
          },
        }
      }
      


      // return {
      //   name: 'PinManagment'
      // }
    }
  },
  async mounted() {
    
  },
  methods: {
    checkKYCStatus() {
      this.$store.dispatch("VERIFY_KYC").then((data) => {
        if (data.status) {
          this.fetchedKycDetails = data.data
        }
      })
    },
  },
};
</script>

<style>
</style>